<template>
  <div class="authoring-wrapper">
    <div class="authoring">
      <div class="flex">
        <ProgressBar
          :stepIndex="6"
          :surveyID="surveyID"
          stepsType="brand-fingerprint"
        />
        <section class="wrapper">
          <a-breadcrumb class="breadcrum breadcrum--authoring">
            <a-breadcrumb-item
              ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
            >
            <a-breadcrumb-item
              ><a href="/products">Products</a></a-breadcrumb-item
            >
            <a-breadcrumb-item>PGNAT</a-breadcrumb-item>
          </a-breadcrumb>

          <div class="wrapper__inner">
            <div class="stepAuthor" ref="stepAuthorSection">
              <div class="page-title page-title--transparent-svg-color">
                <icon-base
                  :width="32"
                  :height="33"
                  :viewBox1="32"
                  :viewBox2="33"
                  iconName="rocket"
                  iconStroke="#4318FF"
                  ><icon-rocket
                /></icon-base>
                <div class="page-title__text">
                  <h1>Launch</h1>
                  <span>Summary & Confirmation</span>
                </div>
              </div>
              <div class="box-wrapper">
                <div>
                  <a-descriptions bordered :column="2">
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryProjectName"
                    >
                      {{ projectSummary ? projectSummary.projectName : "/" }}
                    </a-descriptions-item>

                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryCountry"
                    >
                      <span
                        v-html="
                          projectSummary !== null ? projectSummary.country : '/'
                        "
                      ></span>
                    </a-descriptions-item>

                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryCategory"
                    >
                      {{
                        projectSummary !== null && "category" in projectSummary
                          ? projectSummary.category
                          : "/"
                      }}
                    </a-descriptions-item>

                    <a-descriptions-item label="Brand(s)">
                      <div class="position-r">
                        {{ brands.length > 0 ? brands.join(", ") : "/" }}
                        <Skeleton
                          v-if="loadingData"
                          :borderRadius="4"
                        ></Skeleton>
                      </div>
                    </a-descriptions-item>

                    <a-descriptions-item label="Explicit">
                      <div class="flex items-center sample-size position-r">
                        <Skeleton
                          v-if="loadingData"
                          :borderRadius="4"
                        ></Skeleton>
                        <div
                          @click="openExplicit = !openExplicit"
                          :class="{ disabled: !changeExplicit }"
                          @focusout="openExplicit = false"
                        >
                          <a-select
                            class="sample-size__select"
                            :class="{
                              'sample-size__select--disabled': !changeExplicit,
                            }"
                            @change="setAudience('Explicit', $event, true)"
                            :default-value="explicitValue"
                            :disabled="!changeExplicit"
                            :open="openExplicit"
                            :dropdownMatchSelectWidth="false"
                            :key="Math.random()"
                          >
                            <a-select-option
                              v-for="(item, index) in [
                                75, 100, 150, 200, 250, 300,
                              ]"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </a-select-option>
                          </a-select>
                        </div>
                        <div>
                          <a-button
                            type="primary"
                            class="btn-change-sample"
                            v-if="!changeExplicit"
                            @click="
                              (openExplicit = true), (changeExplicit = true)
                            "
                          >
                            Change</a-button
                          >
                          <a-button
                            v-else
                            type="primary"
                            class="btn-change-sample"
                            @click="
                              (openExplicit = false), (changeExplicit = false)
                            "
                            >Close</a-button
                          >
                        </div>
                      </div>
                    </a-descriptions-item>
                    <a-descriptions-item label="Implicit">
                      <div class="flex items-center sample-size position-r">
                        <Skeleton
                          v-if="loadingData"
                          :borderRadius="4"
                        ></Skeleton>
                        <div
                          @click="openImplicit = !openImplicit"
                          :class="{ disabled: !changeImplicit }"
                          @focusout="openImplicit = false"
                        >
                          <a-select
                            class="sample-size__select"
                            :class="{
                              'sample-size__select--disabled': !changeImplicit,
                            }"
                            @change="setAudience('Implicit', $event, true)"
                            :default-value="implicitValue"
                            :disabled="!changeImplicit"
                            :open="openImplicit"
                            :dropdownMatchSelectWidth="false"
                            :key="Math.random()"
                          >
                            <a-select-option
                              v-for="(item, index) in [200, 250, 300, 350, 400]"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </a-select-option>
                          </a-select>
                        </div>
                        <div>
                          <a-button
                            type="primary"
                            class="btn-change-sample"
                            v-if="!changeImplicit"
                            @click="
                              (openImplicit = true), (changeImplicit = true)
                            "
                          >
                            Change</a-button
                          >
                          <a-button
                            v-else
                            type="primary"
                            class="btn-change-sample"
                            @click="
                              (openImplicit = false), (changeImplicit = false)
                            "
                            >Close</a-button
                          >
                        </div>
                      </div>
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryImplicitComponent"
                    >
                      <div class="position-r">
                        <Skeleton
                          v-if="loadingData"
                          :borderRadius="4"
                        ></Skeleton>

                        <a-checkbox
                          v-model="implicitComponent"
                          @change="setImplicitComponent"
                        >
                          <span v-if="!implicitComponent">Enable</span>
                          <span v-else>Disable</span>
                        </a-checkbox>
                      </div>
                    </a-descriptions-item>
                    <a-descriptions-item label="Target positioning">
                      <div class="position-r">
                        <Skeleton
                          v-if="loadingData"
                          :borderRadius="4"
                        ></Skeleton>
                        {{ targetPositioning }}
                      </div>
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryCustomQuestions"
                    >
                      <template v-if="projectSummary !== null">
                        <ol class="mb-0 pl-15">
                          <li
                            v-for="(
                              item, index
                            ) in projectSummary.customQuestions"
                            :key="index"
                          >
                            {{ item }}
                          </li>
                        </ol>
                        <span v-if="projectSummary.customQuestions.length === 0"
                          >/</span
                        >
                      </template>
                    </a-descriptions-item>
                  </a-descriptions>

                  <div class="launch-step__total">
                    <div class="agreement">
                      <a-checkbox v-model="privacyCheck">
                        <span
                          v-html="langObj['s6-summary'].summaryPrivacy"
                        ></span>
                      </a-checkbox>
                      <span>
                        <a
                          href="#"
                          v-html="langObj['s6-summary'].summaryPrivacyLink"
                          class="terms"
                        ></a>
                      </span>
                      <div v-if="privacyCheckValidation">
                        <p
                          class="validation-text font-size-14 mt-10 font-500"
                          v-html="langObj['s6-summary'].summaryPrivacyMessage"
                        ></p>
                      </div>
                    </div>
                    <div>
                      <a-descriptions bordered :column="1" class="total-table">
                        <a-descriptions-item label="Estimated OOP:">
                          <span class="flex items-center justify-end">
                            ${{
                              formatPrice(
                                getAllPriceDetails &&
                                  getAllPriceDetails.total_price
                                  ? getAllPriceDetails.total_price
                                  : "/"
                              )
                            }}
                            <a-tooltip
                              placement="top"
                              overlayClassName="ant-tooltip--small ant-tooltip--step-nav"
                            >
                              <template slot="title">
                                <span class="d-block"
                                  >Cost for sample: ${{
                                    formatPrice(
                                      getAllPriceDetails &&
                                        getAllPriceDetails.sample_cost
                                        ? getAllPriceDetails.sample_cost
                                        : "/"
                                    )
                                  }}</span
                                >
                                <span class="d-block"
                                  >Maintenance cost: ${{
                                    formatPrice(
                                      getAllPriceDetails &&
                                        getAllPriceDetails.maintenance_cost
                                        ? getAllPriceDetails.maintenance_cost
                                        : "/"
                                    )
                                  }}</span
                                >
                              </template>

                              <a-icon type="question-circle" />
                            </a-tooltip>
                          </span>
                        </a-descriptions-item>
                        <a-descriptions-item label="Enter PO:">
                          <div class="input-group input-group--small">
                            <a-input v-model="po"></a-input>
                          </div>
                        </a-descriptions-item>
                      </a-descriptions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StepNaviBar
              :stepIndex="7"
              :nextDisable="false"
              @nextHandler="nextStep"
              :isEnd="true"
              @prevHandler="prevStep"
              :accountPaymentType="accountPaymentType"
              :loading="
                loading || getPriceLoader || finalPriceCalculationLoader
              "
              prevBtnTooltip="Back to Brand Strategy"
              :conciergeAssistance="false"
              stepsType="brand-fingerprint"
              @editable-survey-updated="handleEditableSurveyUpdate"
            />
          </div>
        </section>
      </div>
      <form :action="apiUrl" method="POST" ref="payLaunchForm">
        <input type="hidden" name="SurveyId" id="SurveyId" :value="surveyID" />
        <input type="hidden" name="Price" id="Price" :value="finalPrice" />
      </form>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import { store } from "@/utils/store.js";
import priceFormating from "@/mixins/priceFormating.js";
import IconBase from "@/components/general/IconBase.vue";
import IconRocket from "@/components/icons/IconRocket.vue";
import Skeleton from "@/components/general/Skeleton.vue";

export default {
  name: "Summary",
  components: {
    ProgressBar,
    StepNaviBar,
    IconBase,
    IconRocket,
    Skeleton,
  },
  mixins: [surveyPriceMixin, priceFormating],
  data() {
    return {
      privacyCheck: true,
      privacyCheckValidation: false,
      surveyID: 0,
      client: {},
      projectSummary: null,
      langObj: {},
      price: null,
      accountPaymentType: null,
      equalPrices: false,
      loading: false,
      apiUrl: null,
      surveyTypeID: null,
      finalPrice: null,
      implicitValue: 200,
      explicitValue: 200,
      openImplicit: false,
      openExplicit: false,
      changeImplicit: false,
      changeExplicit: false,
      user: null,
      editableSurvey: null,
      finalPriceCalculationLoader: false,
      implicitComponent: false,
      po: null,
      costDetails: {
        estimatedCost: null,
        costForSample: null,
        maintenanceCost: null,
        dataProcessing: null,
      },
      loadingData: true,
      brands: [],
      targetPositioning: null,
    };
  },

  watch: {
    privacyCheck(newVal) {
      if (newVal) {
        this.privacyCheckValidation = false;
      }
    },
  },
  computed: {
    getPackTestPrice() {
      return store.surveyPackTestPrice;
    },
    getAllPriceDetails() {
      return store.allPriceDetails;
    },
    getPriceLoader() {
      return store.priceLoader.length > 0;
    },
  },
  methods: {
    prevStep() {
      this.$router.push({
        name: "brand-fingerprint-quotas",
        params: { id: this.surveyID },
      });
    },
    launchSurvey() {
      this.loading = true;

      const data = {
        SurveyId: this.surveyID,
        userId: this.user.EncrypteduserID,
        poValue: this.po,
      };

      const launch = new Promise((resolve) => {
        wsUtils.BrandFingerprintLaunchSurvey(data, resolve);
      });

      launch.then((data) => {
        this.loading = false;
        if (data.success) {
          this.$router.push({
            name: "pgnat-brand-launch-details",
            params: { id: this.$route.params.id },
          });
        } else {
          this.$notification["error"]({
            message: `Oops!`,
            description: data.message,
            placement: "bottomLeft",
            duration: 10,
          });
        }
      });
    },
    nextStep() {
      let runSurveyMode = jsUtils.getRunSurveyMode();
      let runMessage = "";
      let demoUser = this.user.UserType === "Guest" ? true : false;

      if (demoUser) {
        runMessage =
          "DEMO accounts cannot launch surveys. Please upgrade for full access.";
      } else if (!runSurveyMode) {
        runMessage =
          "You can't 'Pay & Launch' study because you haven't filled in all the information for the test.";
      }

      if (!runSurveyMode || demoUser) {
        this.$notification["warning"]({
          message: `Oops`,
          description: runMessage,
          placement: "bottomLeft",
          duration: 10,
        });
      } else {
        if (this.privacyCheck) {
          this.privacyCheckValidation = false;
          jsUtils.setSurveysApiTrigger(true);
          if (this.accountPaymentType === "Invoiced") {
            this.launchSurvey();
          } else {
            this.loading = true;
            this.$refs.payLaunchForm.submit();
          }
          jsUtils.deleteCookie("survey-type");
        } else {
          this.privacyCheckValidation = true;
          const stepAuthorSection = this.$refs.stepAuthorSection;
          stepAuthorSection.scrollTop = stepAuthorSection.scrollHeight;
        }
      }
    },
    setAudience(type, value, notification) {
      if (type === "Implicit") {
        this.implicitValue = value;
      } else {
        this.explicitValue = value;
      }
      this.openImplicit = false;
      this.openExplicit = false;
      this.changeExplicit = false;
      this.changeImplicit = false;
      const data = {
        wsName: "setBrandFingerPrintAudience",
        data: {
          surveyId: this.$route.params.id,
          explicitSampleSize: this.explicitValue,
          implicitSampleSize: this.implicitValue,
          userId: this.user.EncrypteduserID,
        },
      };

      const setAudience = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      setAudience.then((data) => {
        if (data.Success) {
          if (notification) {
            this.$notification["success"]({
              message: "Well done!",
              description: `${type} successfully uploaded.`,
              placement: "bottomLeft",
              duration: 5,
            });
          }

          this.getBrandFingerprintPrice();
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something was wrong. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },

    setImplicitComponent() {
      let data = {
        SurveyID: this.surveyID,
        implicitComponent: this.implicitComponent,
      };
      const setImplicitComponent = new Promise((resolve) => {
        wsUtils.SetImplicitComponent(data, resolve);
      });

      setImplicitComponent.then(() => {});
    },
    handleEditableSurveyUpdate(surveyData) {
      if (
        surveyData &&
        surveyData.SurveyConfig &&
        surveyData.SurveyConfig.SurveyStatus !== "Draft"
      ) {
        this.$router.push("/");
      }
      if (surveyData && surveyData.SurveyConfig) {
        if (surveyData.SurveyConfig.explicitSampleSize === 0) {
          this.setAudience("Explicit", 200);
        } else {
          this.explicitValue = surveyData.SurveyConfig.explicitSampleSize;
        }
      }
      if (surveyData && surveyData.SurveyConfig) {
        if (surveyData.SurveyConfig.implicitSampleSize === 0) {
          this.setAudience("Implicit", 200);
        } else {
          this.implicitValue = surveyData.SurveyConfig.implicitSampleSize;
        }
      }
      if (surveyData && surveyData.brands && surveyData.brands.length > 0) {
        this.brands = surveyData.brands.map((item) => item.brands);
      }
      if (surveyData) {
        this.implicitComponent = surveyData.SurveyConfig.implicitComponent;
      }
      if (surveyData && surveyData.attribbuteData) {
        this.targetPositioning =
          surveyData.attribbuteData.brand_Positioning.name;
      }
      this.loadingData = false;
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
  },
  activated() {
    this.loadingData = true;
    window.scrollTo({
      top: 0,
    });
    this.po = null;
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.surveyID = this.$route.params.id;
    this.user = jsUtils.getUserInfo();
    if (
      window.location.hostname === "app.hotspexlabs.com" ||
      window.location.hostname === "pg.hotspexlabs.com" ||
      window.location.hostname === "d3n7xxuq0fwznj.cloudfront.net" ||
      window.location.hostname === "d30wz03irsnsp8.cloudfront.net" ||
      window.location.hostname === "d11qiq41szmpd8.cloudfront.net"
    ) {
      this.apiUrl =
        "https://admin.hotspexlabs.com/Cart/create-checkout-session";
    } else if (window.location.hostname === "app-uat.hotspexstudio.com") {
      this.apiUrl =
        "https://admin-uat.hotspexstudio.com/Cart/create-checkout-session";
    } else {
      this.apiUrl =
        "https://staging-admin.hotspexstudio.com/Cart/create-checkout-session";
    }

    this.projectSummary = jsUtils.getSurveySummaryData();
    this.accountPaymentType = jsUtils.getAccountPaymentType();
    this.getBrandFingerprintPrice();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
};
</script>

<style lang="scss" scoped>
.info {
  h2 {
    font-size: 16px;
    font-weight: 600;
  }
}
.stepAuthor {
  display: block;
  width: 100%;
  margin: auto;
  max-width: 1010px;
  ::v-deep .ant-descriptions-bordered {
    &.total-table {
      .ant-descriptions-item-label {
        padding: 18px 12px;
        width: 154px;
      }
      .ant-descriptions-item-content {
        min-width: 114px;
      }
    }
    .ant-descriptions-item-label {
      padding: 9px 12px;
      width: 270px;
      max-width: 270px;
      font-size: 12px;
      font-weight: 600;
      background: #f0f0f0;
      border-color: #b9b9b9;
    }
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-view {
    border-color: #b9b9b9;
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-item-content {
    min-width: 140px;
    color: #1c1c1c;
  }
  ::v-deep .ant-descriptions-item-content {
    width: 265px;
    padding: 9px 12px;
    font-size: 12px;
    border-color: #b9b9b9;
  }
  ::v-deep .ant-descriptions-row {
    border-color: #b9b9b9;
  }
}

.agreement {
  padding-right: 20px;
  padding: 16px 12px 13px;
  border-radius: 5px;
  background: #f4f7fe;
  flex: 1;
  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  a {
    color: var(--red);
    text-decoration: underline;
    margin-left: -5px;
    font-weight: 500;
    letter-spacing: -0.32px;
  }

  label {
    font-size: 16px;
    font-weight: 500;
    color: rgba(28, 28, 28, 0.9);
    letter-spacing: -0.32px;
  }

  .terms {
    padding-left: 43px;
    display: inline-flex;
  }

  ::v-deep .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-color: var(--red);
    background: var(--red);
    &:after {
      width: 9px;
      height: 18px;
      top: 45%;
    }
  }

  ::v-deep .ant-checkbox-checked::after {
    border-radius: 50%;
    border-color: var(--red);
  }
}

.btn-change-sample {
  height: 22px;
  padding: 0 7px;
  font-size: 13px;
  margin-left: 8px;
}

.sample-size {
  &__select {
    width: 48px;
    ::v-deep .ant-select-arrow {
      pointer-events: none;
      font-size: 9px;
      right: 4px;
    }
    ::v-deep .ant-select-selection__rendered {
      margin-right: 0;
      margin-left: 6px;
    }
    &--disabled {
      ::v-deep .ant-select-selection {
        border-color: transparent;
        background: transparent;
        color: rgba(0, 0, 0, 0.65);
      }
      ::v-deep .ant-select-arrow {
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }
  }
  .ant-btn {
    width: 60px;
    margin-left: 5px;
  }
}

.disabled {
  pointer-events: none;
}

.validation-text {
  color: red;
}
</style>

<style lang="scss">
.ant-descriptions-item-label {
  font-weight: 500;
  background-color: #f6fcff;
  color: var(--hsgrey) !important;
}

.launch-step {
  padding-bottom: 80px !important;
  &__video-info {
    margin: 40px 0 0;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #e8e8e8;
    border-radius: 4px;
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-right: 1px solid #e8e8e8;
      padding: 16px 24px;
      &:last-child {
        border-right: 0;
      }
    }
    .ant-table-tbody {
      tr {
        &:hover {
          td {
            background: #fff !important;
          }
        }
      }
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    gap: 24px;

    .ant-descriptions {
      max-width: 376px !important;
      width: 100%;
      font-weight: 600;
      .anticon {
        margin-left: 8px;
        display: block;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.report-option-dropdown {
  width: 152px;
}
</style>
